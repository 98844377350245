import { createApp } from 'vue';
import App from '@/app/app.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { i18nPlugin } from '@/utils/i18n';
import filters from '@/filters';
import MixinComponent from '@/mixin-component';
import { Title, ScrollWatcher, TextareaAutosize, Tooltip, Scroll, Resize } from '@/directives';

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// enable/disable Bootstrap JS if you want to use :
// import 'bootstrap/js/src/alert';
// import 'bootstrap/js/src/button';
// import 'bootstrap/js/src/carousel';
// import 'bootstrap/js/src/collapse';
// import 'bootstrap/js/src/dropdown';
// import 'bootstrap/js/src/modal';
// import 'bootstrap/js/src/popover';
// import 'bootstrap/js/src/scrollspy';
// import 'bootstrap/js/src/tab';
// import 'bootstrap/js/src/toast';
// import 'bootstrap/js/src/tooltip';
// import 'bootstrap/js/src/util';
// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// all global SCSS to import (Bootstrap, FontAwesome, custom, ...)
import '@/styles/_imports.scss';

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// dayjs locale
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import { IDocument } from '@/types';
dayjs.locale('fr');
dayjs.extend(utc);

declare global {
  interface Window {
    __kcreferentiel: any;
  }
}

function init() {
  // Set side-menu background color default green if production, custom color if not
  const devColor = `#${import.meta.env.VITE_BACKGROUND_COLOR || '4389ab'}`;
  document.documentElement.style.setProperty('--menu-color', import.meta.env.MODE === 'production' ? '#4d4f5c' : devColor);

  // If not production envrionment, redraw favicon
  if (import.meta.env.MODE !== 'production') {
    // Get favicon link or create new link element
    let link = document.querySelector("link[rel~='icon']") as Element;
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'shortcut icon');
      document.head.appendChild(link);
    }
    // Get the favicon
    const faviconUrl = link.getAttribute('href') || `${window.location.origin}/img/icons/favicon-32x32.png`;

    // Create new image
    const img = document.createElement('img');

    function onImageLoaded() {
      // Recolor the image
      const canvas = document.createElement('canvas');
      canvas.width = 48;
      canvas.height = 48;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.drawImage(img, 0, 0);
      context.globalCompositeOperation = 'source-in';
      context.fillStyle = devColor;
      context.fillRect(0, 0, 48, 48);
      context.fill();
      link.setAttribute('type', 'image/x-icon');
      link.setAttribute('href', canvas.toDataURL());
    }
    img.addEventListener('load', onImageLoaded);

    // Set the new favicon
    img.src = faviconUrl;
  }
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  // checking user-agent for all IE
  const isIE = /Trident|MSIE/.test(navigator.userAgent);
  if (isIE) {
    alert('Votre navigateur est trop ancien.');
    return;
  }

  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  // watch material-icons loading, hiding until it's loaded
  const style = document.createElement('style');
  style.innerHTML = `.material-icons, .material-icons-outlined {
    opacity: 0 !important;
    max-width: 24px !important;
    overflow: hidden !important;
  }`;
  document.head.appendChild(style);
  document.fonts.addEventListener('loadingdone', function (event) {
    if ((event as FontFaceSetLoadEvent).fontfaces.some((fontface) => fontface.family.replace(/['"]+/g, '') === 'Material Icons')) {
      document.head.removeChild(style);
    }
  });
  // ▀▀▀▀▀▀▀▀▀▀▀▀

  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);

  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  const app = createApp(App)
    .mixin(MixinComponent)
    .directive('tooltip', Tooltip)
    .directive('title', Title)
    .directive('scroll-watcher', ScrollWatcher)
    .directive('scroll', Scroll)
    .directive('resize', Resize)
    .directive('textarea-autosize', TextareaAutosize)
    .use(i18nPlugin)
    .use(pinia)
    .use(router);
  app.config.globalProperties.$filters = filters;
  app.mount('#root');
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
}
init();

declare module '@vue/runtime-core' {
  interface ComponentInternalInstance {
    provides: Record<string, unknown>;
  }

  interface ComponentCustomProperties {
    $filters: {
      [key: string]: Function;
    };
    $refs: {
      [key: string]: HTMLElement | any;
    };
    setDocumentTitle(pageTitle: string | null): void;
    downloadUrl(document: IDocument): string;
  }
}
