import {defineStore} from 'pinia';

export interface IStateSidePanel {
  display: boolean;
  automaticClose: boolean;
  title: string;
  subtitle: string;
  component: { loader: any } | null;
  props: any;
}

export interface ISidePanelOptions extends Omit<IStateSidePanel, 'component'> {
  component: any;
}

export const useSidePanelStore = defineStore('sidePanel', {
  state: (): IStateSidePanel => {
    return {
      display: false, // Panel affiché
      automaticClose: false, // Panel fermé via le router et non manuellement
      title: '', // Titre du panel
      subtitle: '', // Sous-titre du panel
      component: null,  // Composant affiché
      props: {}  // Propriétés associées au composant affiché
    };
  },
  actions: {
    set(sidePanel: Partial<ISidePanelOptions>) {
      this.display = true;
      this.automaticClose = false;
      this.component = null;
      this.title = '';
      this.subtitle = '';
      this.props = {};

      Object.keys(sidePanel).forEach((key: string) => {
        this[key] = key === 'component' ? {loader: sidePanel.component} : sidePanel[key as keyof IStateSidePanel];
      });
    }
  }
});
