import { defineStore } from 'pinia';
import { DISPLAY_TYPE } from '@/const';

export interface IStateSettings {
  favoriteDisplay: string;
}

export const useSettingsStore = defineStore('settings', {
  persist: true,
  state: (): IStateSettings => {
    return {
      favoriteDisplay: DISPLAY_TYPE.CARD
    };
  },
  actions: {
    setFavoriteDisplay(display: string) {
      this.favoriteDisplay = display;
    }
  }
});
