/**
 * Constante contenant les urls des apis.
 */
export const apis = {
  documents: {
    me: '/api/documents',
    byId: (id: string) => `/api/documents/${id}`,
    download: (id: string) => `/api/documents/${id}/telecharger`,
    search: '/api/documents/search'
  },
  programmes: {
    me: '/api/programmes',
    byId: (id: string) => `/api/programmes/${id}`,
    logo: (id: string) => `/api/programmes/${id}/logo`
  },
  partner: {
    me: `/api/partenaires`,
    byId: (id: string) => `/api/partenaires/${id}`,
    logo: (id: string) => `/api/partenaires/${id}/logo`
  },
  projects: {
    me: '/api/versions',
    export: '/api/versions/export',
    byId: (id: string) => `/api/versions/${id}`,
    device: (id: string) => `/api/dispositifs/${id}`,
    eventLogs: (id: string) => `/api/versions/${id}/evenements`,
    thumbnail: (id: string) => `/api/versions/${id}/vignette`,
    updateState: (id: string) => `/api/versions/${id}/etat`,
    duplicate: (id: string) => `/api/dispositifs/${id}/dupliquer`,
    versionate: (id: string) => `/api/dispositifs/${id}/versions`,
    askCorrection: (id: string) => `/api/versions/${id}/email?email=correction_request`,
    correctionDone: (id: string) => `/api/versions/${id}/email?email=correction_taken_account`,
    appelAProjet: '/api/appel_a_projets',
    depositHelp: `/api/aides_au_depot`,
    studyHelp: '/api/cahier_des_charges_etudes',
    studyHelpDoc: '/api/cahier_des_charges_etude_documents',
    eligibilite: '/api/eligibilites',
    eligibiliteDoc: '/api/eligibilite_documents',
    partner: '/api/version_partenaires',
    piecesSansModele: '/api/piece_sans_modeles',
    piecesAvecModele: '/api/piece_avec_modeles',
    listeDePiece: '/api/liste_de_pieces',
    versionListeDePiece: '/api/version_liste_de_pieces',
    referents: `/api/referents`
  },
  users: {
    me: '/api/utilisateurs',
    synchronize: '/api/utilisateurs/synchronisation',
    byId: (id: string) => `/api/utilisateurs/${id}`
  },
  referentials: {
    finance: '/api/referentiel_interne/financements',
    financeLogo: (id: string) => `/api/referentiel_interne/financements/${id}/logo`,
    funds: '/api/referentiel_interne/fonds',
    geographicCoverage: '/api/referentiel_interne/couvertures_geographiques',
    geographicArea: '/api/referentiel_interne/zones_geographiques',
    theme: '/api/referentiel_interne/thematiques',
    typeDoc: '/api/referentiel_interne/types_document',
    typeDocById: (id: string) => `/api/referentiel_interne/types_document/${id}`,
    projectType: '/api/referentiel_interne/types_projet',
    projectTarget: '/api/referentiel_interne/cibles_projet',
    subType: '/api/referentiel_interne/sous_types_appel_a_projet',
    ug: '/api/referentiel_interne/unites_gestion',
    ugById: (id: string) => `/api/referentiel_interne/unites_gestion/${id}`,
    listeDepense: '/api/referentiel_interne/depenses',
    eventType: '/api/referentiel_interne/types_evenement',
    origineFonds: `/api/referentiel_externe/origines_fonds_budgetaires`,
    programmeBudgetaire: `/api/referentiel_externe/programmes_budgetaires`,
    sousProgrammeBudgetaire: `/api/referentiel_externe/sous_programmes_budgetaires`,
    axeLisaActivites: '/api/referentiel_externe/lisa/activites',
    axeLisaSystemesAide: '/api/referentiel_externe/lisa/systemes_aide',
    axeLisaThematiques: '/api/referentiel_externe/lisa/thematiques',
    managementFee: '/api/referentiel_interne/forfaits_frais_gestion',
    codeNaf: '/api/referentiel_interne/naf'
  },
  admin: {
    cache: '/api/admin/cache'
  }
} as Record<string, any>;
