import { ComponentInternalInstance, VNodeChild } from 'vue';
import { IReferential } from '@/types';

export function findChildren(vnode?: VNodeChild): ComponentInternalInstance[] {
  if (!vnode || typeof vnode !== 'object') {
    return [];
  }

  if (Array.isArray(vnode)) {
    return vnode
      .map((child) => findChildren(child))
      .filter((v) => v)
      .flat(1);
  } else if (Array.isArray(vnode.children)) {
    return vnode.children
      .map((child) => findChildren(child))
      .filter((v) => v)
      .flat(1);
  } else if (vnode.component) {
    return [vnode.component, ...findChildren(vnode.component?.subTree)].filter((v) => v).flat(1);
  }

  return [];
}

export function getUid() {
  return getUid._uid++;
}

getUid._uid = 0;

export function deepEqual(a: any, b: any): boolean {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) {
    // If the values are Date, compare them as timestamps
    return false;
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false;
  }

  const props = Object.keys(a);

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false;
  }

  return props.every((p) => deepEqual(a[p], b[p]));
}

export function getAPIUrl(url: string) {
  return url.startsWith('http') ? url : `${import.meta.env.VITE_API_URL}${url}`;
}

export function stripTags(originalString: string) {
  return originalString.replace(/(<([^>]+)>)/gi, '');
}

export function sortReferential(datas: any, groupBy: string | null | undefined = undefined, lastCode: string | null | undefined = undefined) {
  datas.map((item: IReferential) => {
    if (item.parent) {
      item.idSort = item.parent.code + item.code;
    } else {
      item.idSort = item.code;
    }

    // Autre doit etre en dernier
    if (lastCode && item.code === lastCode) {
      // Item 'Autre'
      item.idSort = 'ZZZZZZ';
    } else if (lastCode && groupBy && item[groupBy] && item[groupBy].code === lastCode) {
      // Items enfants de 'Autre'
      item.idSort = 'ZZZZZZ2';
    }

    return item;
  });
  return datas.sort((a: IReferential, b: IReferential) => (a.idSort > b.idSort ? 1 : -1));
}
