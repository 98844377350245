/* istanbul ignore file */
import { LS, PROJECT_SECTIONS } from '@/const';
import { i18n } from '@/utils/i18n';
import kebabcase from 'lodash.kebabcase';
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { usePopupStore } from '@/stores/popup';
import { useOverlayStore } from '@/stores/overlay';
import { useSidePanelStore } from '@/stores/side-panel';
import { useUserStore } from '@/stores/user';
import { ROLES, TECHNICAL_ROLES } from '@/types';

const AVAILABLE_SIDE_PANELS = Object.values(PROJECT_SECTIONS).map((s) => kebabcase(s));
const projectSidePanelRegexp = AVAILABLE_SIDE_PANELS.join('|');

/* eslint-disable max-len */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/projects',
    meta: { permittedRoles: [ROLES.CONSULTATION] }
  },
  {
    path: '/projects/new-project',
    name: 'new-project',
    meta: { permittedRoles: [ROLES.EDITION] },
    component: () => import('@/views/projects/new-project/new-project.vue')
  },
  {
    path: '/projects/:projectId',
    name: 'project-by-id',
    meta: { permittedRoles: [ROLES.CONSULTATION, ROLES.EXTERNAL_CONSULTATION] },
    component: () => import('@/views/projects/project/project.vue'),
    children: [
      {
        name: 'project-panel',
        path: ':sidePanel(' + projectSidePanelRegexp + ')',
        meta: {
          permittedRoles: (route: RouteLocationNormalized) => {
            return route.params.sidePanel === PROJECT_SECTIONS.HISTORIC ? [ROLES.CONSULTATION] : [ROLES.EDITION];
          }
        },
        component: {}
      }
    ]
  },
  {
    path: '/projects',
    name: 'projects',
    meta: { permittedRoles: [ROLES.CONSULTATION] },
    component: () => import('@/views/projects/projects.vue')
  },
  {
    path: '/referentials',
    name: 'referentials',
    meta: { permittedRoles: [ROLES.ADMIN] },
    component: () => import('@/views/referentials/referentials.vue')
  },
  {
    path: '/referentials/:referentialId',
    name: 'referential',
    meta: { permittedRoles: [ROLES.ADMIN] },
    component: () => import('@/views/referentials/referential/referential.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: { permittedRoles: [ROLES.ADMIN] },
    component: () => import('@/views/users/users.vue'),
    children: [
      {
        name: 'user-new',
        path: 'new',
        meta: { permittedRoles: [ROLES.ADMIN], sidePanel: 'new' },
        component: {}
      },
      {
        name: 'user-panel',
        path: ':userId',
        meta: { permittedRoles: [ROLES.ADMIN], sidePanel: 'edit' },
        component: {}
      }
    ]
  },
  {
    path: '/documents',
    name: 'documents',
    meta: { permittedRoles: [ROLES.ADMIN] },
    component: () => import('@/views/documents/documents.vue'),
    children: [
      {
        name: 'document-new',
        path: 'new',
        meta: { permittedRoles: [ROLES.ADMIN], sidePanel: 'new' },
        component: {}
      },
      {
        name: 'document-panel',
        path: ':documentId',
        meta: { permittedRoles: [ROLES.ADMIN], sidePanel: 'edit' },
        component: {}
      }
    ]
  },
  {
    path: '/ui',
    name: 'ui',
    meta: { permittedRoles: [ROLES.CONSULTATION] },
    component: () => import('@/views/example-ui/example-ui.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { permittedRoles: [] },
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/get-user',
    name: 'get-user',
    meta: { permittedRoles: [] },
    component: () => import('@/views/get-user/get-user.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { permittedRoles: [] },
    component: () => import('@/views/logout/logout.vue')
  },
  {
    path: '/no-services',
    name: 'no-services',
    meta: { showSidebar: false, permittedRoles: [] },
    component: () => import('@/views/no-services/no-services.vue')
  },
  {
    path: '/no-access',
    name: 'no-access',
    meta: { showSidebar: false, permittedRoles: [] },
    component: () => import('@/views/no-access/no-access.vue')
  },
  {
    path: '/not-found',
    alias: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: { permittedRoles: [] },
    component: () => import('@/views/not-found/not-found.vue')
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
  /*
  scrollBehavior(to, from, savedPosition) {
    if (to.name?.split('_')[0] === from.name?.split('_')[0]) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
  */
});

function storeUpdates() {
  const popupStore = usePopupStore();
  const overlayStore = useOverlayStore();
  const sidePanelStore = useSidePanelStore();

  // close possible overlay
  if (!popupStore.display || !popupStore.persistent) {
    overlayStore.$reset();
    popupStore.$reset();
  }

  if (sidePanelStore.display) {
    sidePanelStore.display = false;
    sidePanelStore.automaticClose = true;
  }
}

function getRedirectAfterLogin(from: RouteLocationNormalized, to: RouteLocationNormalized) {
  let redirectAfterLogin = null;
  const blacklistRedictionPath = ['/login', '/logout', '/get-user', '/no-access', '/no-services', '/not-found'];
  try {
    redirectAfterLogin = JSON.parse(localStorage.getItem(LS.REDIRECT_AFTER_LOGIN) || '');
  } catch (err) {}
  // save route for post login redirection of the visited page
  if (blacklistRedictionPath.includes(to.path) === false && blacklistRedictionPath.includes(from.path) === false) {
    localStorage.setItem(LS.REDIRECT_AFTER_LOGIN, JSON.stringify({ path: to.path, timestamp: Date.now() }));
  }

  return redirectAfterLogin;
}

function getRoutePermittedRoles(route: RouteLocationNormalized) {
  return typeof route.meta.permittedRoles === 'function' ? route.meta.permittedRoles(route) : route.meta.permittedRoles;
}

function removeRedirectAfterLoginIfDone(to: RouteLocationNormalized) {
  try {
    const storeData = JSON.parse(localStorage.getItem(LS.REDIRECT_AFTER_LOGIN) || '');
    // Remove stored redirection, because page is actually loading and don't need to be saved
    // Avoid loading the same page when closing / opening tab in browser
    if (storeData?.path === to.path) localStorage.removeItem(LS.REDIRECT_AFTER_LOGIN);
  } catch (err) {}
}

function getNotGrantedRoute() {
  const userStore = useUserStore();

  if (!userStore.user || userStore.user.roles.includes(TECHNICAL_ROLES.CONNECTED) === false) {
    return { path: '/login', replace: true };
  } else {
    localStorage.removeItem(LS.REDIRECT_AFTER_LOGIN);
    if (userStore.user.roles.some((role: string) => role !== TECHNICAL_ROLES.USER)) {
      return { path: '/no-access', replace: true };
    } else {
      return { path: '/no-rights', replace: true };
    }
  }
}

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// before every route change, it checks if the user level is enough to access to the requested page
router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  document.title = i18n.t('title');
  const redirectionOnLogin = getRedirectAfterLogin(from, to);
  const permittedRoles = getRoutePermittedRoles(to);

  if (userStore.user) {
    if (from.path === '/get-user' && redirectionOnLogin && to.path !== redirectionOnLogin.path) {
      next({ path: redirectionOnLogin.path });
      localStorage.removeItem(LS.REDIRECT_AFTER_LOGIN);
    } else if (userStore.isGranted(permittedRoles)) {
      next();
      removeRedirectAfterLoginIfDone(to);
    } else {
      next(getNotGrantedRoute());
    }
  } else if (permittedRoles?.length) {
    next({ path: '/login', replace: true });
  } else {
    next();
  }

  storeUpdates();
});

export default router;
