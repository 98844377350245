import debounce from 'lodash.debounce';
import { SCROLL_WATCHER } from '@/const';
import { useTooltip } from '@/composables/tooltip';
import { ref } from 'vue';
import { DebouncedFunc } from 'lodash';
import throttle from 'lodash.throttle';

// Directive pour tronquer en '...' les textes trop longs tout en ajoutant un title sur eux
export const Title = {
  mounted: function (el: any, binding: any) {
    // L'élément ajoute "..." si le titre est trop long
    el.classList.add('text-overflow');
    // Au hover affiche le contenu du texte
    el.title = el.innerText;
    if (binding.arg === 'observe' && binding.value === true) {
      const callback = function () {
        el.title = el.innerText;
      };
      el['v-observer'] = new MutationObserver(callback);
      el['v-observer'].observe(el, { characterData: true, childList: true, subtree: true });
    }
  },
  unmounted: function (el: any) {
    if (el['v-observer'] instanceof MutationObserver) el['v-observer'].disconnect();
  }
};

// Directive pour redimensionner le <textarea> selon son contenu
function onInput(ev: any) {
  const lineHeight = parseFloat(getComputedStyle(ev.target).lineHeight);
  ev.target.rows = 1; // on reset la taille au minimum pour bien recalculer la hauteur en cas de suppression de ligne
  ev.target.rows = Math.ceil((ev.target.scrollHeight - lineHeight) / lineHeight);
}
function handleIntersectionObserve(ev: any) {
  if (ev[0]?.isIntersecting) ev[0].target.dispatchEvent(new Event('input'));
}
export const TextareaAutosize = {
  mounted: function (el: any) {
    el.addEventListener('input', onInput);
    el.dispatchEvent(new Event('input'));
    // Ajout d'un observer sur la présence de l'élément à l'écran
    // Utilisé dans le cas de l'initialisation d'un textarea dans un parent display:none pour recalculer la hauteur lors de son apparition
    el['v-observer'] = new IntersectionObserver(handleIntersectionObserve);
    el['v-observer'].observe(el);
  },
  unmounted: function (el: any) {
    el.removeEventListener('input', onInput);
    if (el['v-observer'] instanceof IntersectionObserver) el['v-observer'].disconnect();
  }
};

// Directive pour écouter le scroll et déclencher un event si on arrive en bas de l'élément html
const onScroll = debounce((e: any) => {
  const { target } = e;
  if (target.offsetHeight + target.scrollTop + target.offsetHeight * 0.2 > target.scrollHeight) {
    target.dispatchEvent(new Event(SCROLL_WATCHER.REACH_BOTTOM));
  }
}, 100);

export const ScrollWatcher = {
  mounted: function (el: any) {
    el.addEventListener('scroll', onScroll);
  },
  unmounted: function (el: any) {
    el.removeEventListener('scroll', onScroll);
  }
};

function createListener(event: string, symbol: symbol) {
  return {
    mounted(el: any, binding: any) {
      const waiting = binding.arg ? parseInt(binding.arg, 10) : null;
      el[symbol] = ref<DebouncedFunc<() => void>>(throttle(binding.value, waiting ?? 300));
      el.addEventListener(event, el[symbol]);
    },
    unmounted(el: any) {
      el.removeEventListener(event, el[symbol]);
    }
  };
}
const scrollEventSymbol = Symbol.for('r2da:ScrollEvent');

export const Scroll = {
  mounted(el: any, binding: any) {
    const waiting = binding.arg ? parseInt(binding.arg, 10) : null;
    const observed = binding.modifiers.window ? window : el;
    const event = throttle(binding.value, waiting ?? 300);

    el[scrollEventSymbol] = { observed, event };
    observed.addEventListener('scroll', el[scrollEventSymbol].event);
  },
  unmounted(el: any) {
    el[scrollEventSymbol].observed.removeEventListener('scroll', el[scrollEventSymbol].event);
  }
};

const resizeEventSymbol = Symbol.for('r2da:ResizeEvent');

export const Resize = {
  mounted(el: any, binding: any) {
    const waiting = binding.arg ? parseInt(binding.arg, 10) : null;
    const resizeObserver = new ResizeObserver(throttle(binding.value, waiting ?? 300));

    resizeObserver.observe(el);

    el[resizeEventSymbol] = resizeObserver;
  },
  unmounted(el: any) {
    el[resizeEventSymbol].disconnect();
  }
};

const { create, destroy } = useTooltip();

export const Tooltip = {
  mounted: function (el: any) {
    create(el);
  },
  unmounted: function (el: any) {
    destroy(el);
  }
};
