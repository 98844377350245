import tippy, { hideAll } from 'tippy.js';

interface Tooltip {
  create: (el: any) => void;
  destroy: (el: any) => void;
}

function isSameWidth(el: any) {
  return new Promise((resolve) => {
    window.requestAnimationFrame(() => {
      if (el.tagName === 'INPUT') {
        resolve(el.scrollWidth < el.offsetWidth);
      } else {
        resolve(el.scrollWidth === el.offsetWidth);
      }
    });
  });
}

async function checkContentTooltip(el: any, contentType: string) {
  let content;
  if (contentType === 'inner' && (await isSameWidth(el))) {
    content = '';
  } else if (contentType === 'attribute') {
    content = el.getAttribute('data-tooltip-content');
  } else if (el.tagName === 'INPUT') {
    content = el.value;
  } else {
    content = el.innerText;
  }
  if (content?.trim().length) {
    if (el.tippy && !el.isDestroyed) {
      el.tippy.setContent(content);
      el.tippy.show();
    } else {
      el.setAttribute('tabindex', 0);
      el.tippy = tippy(el, {
        content: content,
        allowHTML: true,
        placement: 'top',
        interactive: false,
        appendTo: document.body,
        zIndex: 100000,
        offset: [0, 4],
        delay: [700, 0],
        maxWidth: 600,
        onShow(instance) {
          hideAll({ exclude: instance });
        }
      });
    }
  } else {
    if (el.tippy) el.tippy.hide();
    el.removeAttribute('tabindex');
  }
}

export function useTooltip(): Tooltip {
  return {
    create(el: any) {
      const callback = function (mutationsList: any[]) {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes') {
            checkContentTooltip(el, 'attribute');
          } else {
            checkContentTooltip(el, 'inner');
          }
        }
      };

      el['v-observer'] = new MutationObserver(callback);
      if (el.getAttribute('data-tooltip-content')) {
        // explicit content
        el['v-observer'].observe(el, { attributes: true, attributeFilter: ['data-tooltip-content'] });
        checkContentTooltip(el, 'attribute');
      } else {
        // read inner content to set tooltip content
        el['v-observer'].observe(el, { characterData: true, childList: true, subtree: true });
        checkContentTooltip(el, 'inner');

        if (el.tagName === 'INPUT') {
          el.addEventListener('input', function () {
            checkContentTooltip(el, 'inner');
          });
        }
      }
    },

    destroy(el: any) {
      if (el['v-observer'] instanceof IntersectionObserver) el['v-observer'].disconnect();
      if (el.tippy) el.tippy.destroy();
      el.removeAttribute('tabindex');
    }
  };
}
