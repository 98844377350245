import { defineComponent, defineAsyncComponent } from 'vue';
import { useTooltip } from '@/composables/tooltip';

const { create, destroy } = useTooltip();

const SideBar = defineAsyncComponent(() => import('@/views/side-bar/side-bar.vue'));
const FogOverlay = defineAsyncComponent(() => import('@/components/fog-overlay/fog-overlay.vue'));
const SidePanel = defineAsyncComponent(() => import('@/components/side-panel/side-panel.vue'));
const Popup = defineAsyncComponent(() => import('@/components/popup/popup.vue'));

export default defineComponent({
  name: 'App',
  components: { FogOverlay, Popup, SideBar, SidePanel },
  computed: {
    showSidebar() {
      return (typeof this.$route.meta.showSidebar === 'undefined' || this.$route.meta.showSidebar) && !this.userStore.isExternalConsultantOnly;
    }
  },
  data() {
    return {
      mutation: null as MutationObserver | null,
      tooltips: [] as Element[]
    };
  },
  mounted: function () {
    this.mutation = new MutationObserver(this.onMutation);
    this.mutation.observe(this.$el, { attributes: true, characterData: true, childList: true, subtree: true });
  },
  methods: {
    onMutation: function (mutationsList: any) {
      for (const mutation of mutationsList) {
        mutation.removedNodes.forEach((el: Element) => {
          // en cas de suppression d'un noeud direct
          if (this.tooltips.includes(el)) {
            destroy(el);
            this.tooltips = this.tooltips.filter((t: Element) => {
              return t !== el;
            });
          }
          // en cas de suppression d'un parent (majorité des cas)
          this.tooltips.forEach((tip: Element) => {
            if (el.contains(tip)) {
              destroy(tip);
              this.tooltips = this.tooltips.filter((t: Element) => {
                return t !== tip;
              });
            }
          });
        });
      }
      const todoTooltips = document.querySelectorAll('[v-tooltip]');
      todoTooltips.forEach((el) => {
        el.removeAttribute('v-tooltip');
        create(el);
        this.tooltips.push(el);
      });
    }
  }
});
