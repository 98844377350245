import { FORM_WRAPPER_EVENTS_TYPES, GETTER_WRAPPER_LOAD_TYPES } from '@/const';
import { IUser } from '@/views/users/users';

export interface IReferential {
  id: string;
  code: string | number;
  dateDebut: string;
  dateFin: string;
  dateDerniereModification: string;
  utilisateurDerniereModification: string;
  ugDeconcentree?: boolean;

  [key: string]: any;
}

export interface ISection {
  title: string;
  read: any;
  panel: any;
  readProps?: {
    disabled?: boolean;
    buttonLabel?: string;
  };
}

export interface IRefMapping {
  [key: string]: string | IRefMapping;
}

export interface IAAPReleve {
  id: string;
  dateDebut: string;
  dateFin: string;
}

export interface IProgram {
  id?: string;
  acronyme: string;
  logoUrl: string;
}

export interface IAAP {
  id: string;
  acronyme: string;
  releves: IAAPReleve[];
  sousType: Partial<IReferential>;
  documentResultats: IDocument | null;
  dateResultats: string;
  courrielContactAap: string;
  appelConjoint: boolean;
  leader: boolean | null;
  programme: IProgram | null;
  typeMultiPhase: 'pre-depot' | 'depot' | null;
  aapPredepot: {  id: string, version: { id: string, titre: string, dispositif: { id: string, idFonctionnel: string } } } | null;
  aapDepot: { id: string, version: { id: string, titre: string, dispositif: { id: string, idFonctionnel: string } } } | null;
}

export interface IDocument {
  id: string;
  auteur: string;
  dateCreation: string;
  dateModification: string;
  nomFichier: string;
  typeMime: string;
  poids: string;
  type: IReferential | null;
  titre: string;
  version: string;
}

export interface IPartenaire {
  id: string;
  nom: string;
  url: string;
  autreUrl: string | null;
  logoUrl: string | null;
}

export interface IPartenaireDispositif {
  id: string;
  zoneGeo: IReferential | null;
  partenaire: IPartenaire;
}

export interface IEligibilite {
  id?: string;
  documents: IOrderedDocument[];
  texteEligibilite: string | null;
}

export interface ICDC {
  id?: string;
  documents: IOrderedDocument[];
  precision: string | null;
  ordre?: number;
}

export interface IOrderedDocument {
  id?: string;
  document: IDocument;
  ordre: number;
}

export interface IReferent {
  id?: string;
  nom: string;
  prenom: string;
  email: string;
  uniteDeGestion?: IReferential;
}

export interface IPieceSansModele {
  id?: string;
  obligatoire: boolean;
  piece: string;
  ordre: number;
  versionDebut: number;
}

export interface IPieceAvecModele extends IOrderedDocument {
  statutJuridique?: IReferential | null;
  typeProjet?: IReferential | null;
  obligatoire?: boolean;
  versionDebut: number;
}

export interface IVersionListeDePiece {
  id: string;
  critere: null | string;
  ordre: number;
  listeDePiece: {
    id: string;
    piecesSansModele: IPieceSansModele[];
    piecesAvecModele: IPieceAvecModele[];
  }
}

export interface IAideAuDepot {
  id: string;
  typeAide: IReferential;
  document: IDocument;
}

// Rôles back triés hiérarchiquement
export enum ROLES {
  ADMIN = 'ROLE_ADMIN',
  PUBLICATION = 'ROLE_PUBLICATION',
  VALIDATION = 'ROLE_VALIDATION',
  VALIDATION_RAJ_DAF_DAJ = 'ROLE_VALIDATION_RAJ_DAF_DAJ',
  DAF_DAJ = 'ROLE_DAF_DAJ',
  RAJ = 'ROLE_RAJ',
  EDITION = 'ROLE_EDITION',
  CONSULTATION = 'ROLE_CONSULTATION',
  EXTERNAL_CONSULTATION = 'ROLE_EXTERNAL_CONSULTATION'
}

export enum TECHNICAL_ROLES {
  CONNECTED = '__CONNECTED__',
  USER = 'ROLE_USER'
}

export type USER_ROLES = ROLES | TECHNICAL_ROLES;

export interface IFormWrapperEventOptions {
  bypassValidation?: boolean;
  params?: unknown;
}

export interface IFormWrapperEvent {
  id: string;
  data?: unknown;
  state?: FORM_WRAPPER_EVENTS_TYPES;
  options?: IFormWrapperEventOptions;
}

export interface IFormCall {
  postData?: () => any;
  apiSave: (() => string) | string;
  onUploadProgress?: () => any;
  httpMethod?: string;
  priority?: number;
  onResolve?: (data: any) => void;
  onReject?: (error: any) => void;
}

export interface IManagementDocument extends Partial<Omit<IDocument, 'type' | 'titre'>> {
  type: string | null;
  titre: string | null;
}

export interface IGetterWrapperEvent {
  id: string;
  filterApiLoad: string[];
  loadType: GETTER_WRAPPER_LOAD_TYPES;
}

export interface IRouteLoad {
  id: string;
  url: string;
  options: Record<string, unknown>;
  ignoreFail?: boolean;
}

export interface IChooserData {
  id: string;
  value: string;
}

export interface IError {
  type: string;
  message: string;
  level?: string;
}

export interface IErrors {
  response?: {
    status: number;
    data: { message: string; errors?: IError[]; violations?: IError[] };
  };
}

export interface ISanitizedErrors {
  message: string;
  details: IError[];
}

export interface IListTableHeader {
  label: string | null;
  value: string;
  class?: string;
}

export interface IButtonInfos {
  id: string;
  value: string;
  disabled: boolean;
  attributes: string[];
}

export interface IScrollMenuItem {
  hash: string;
  label: string;
  node?: HTMLElement | null;
}

export type IExtendedUser = IUser & { fullname: string };
