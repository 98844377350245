export function invert(obj: Record<string, any>): Record<string, any> {
  return Object.keys(obj).reduce((res, k) => Object.assign(res, { [obj[k]]: k }), {});
}

export function sortByKeys(obj: Record<string, any>, sortFct: (a: string, b: string) => number): Record<string, any> {
  return Object.keys(obj)
    .sort(sortFct)
    .reduce(
      (p, c) => {
        p[c] = obj[c];
        return p;
      },
      {} as Record<string, any>
    );
}

export function getNestedValue(obj: Record<string, any>, path: string, fallback: any): any {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function getObjectValueByPath(obj: Record<string, any> | null, path: any, fallback: any): any {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback);
}

export function toProxyForUpdatesTracking<T>(obj: Record<string, any>, excludedProperties: string[] = [], updatedProperty: string = 'updated'): T {
  return new Proxy(obj, {
    set: function (object: Record<string, any>, prop: string, value: any) {
      const updated = Reflect.set(object, prop, value);
      if (updated && !excludedProperties.includes(prop)) {
        object[updatedProperty] = true;
      }
      return updated;
    }
  }) as T;
}

export function omit(obj: Record<string, any>, keys: string[]): Record<string, any> {
  return Object.keys(obj)
    .filter((k) => !keys.includes(k))
    .reduce((res: Record<string, any>, k: string) => Object.assign(res, { [k]: obj[k] }), {});
}

export function sort(obj: Record<string, any>): Record<string, any> {
  return Object.keys(obj)
    .sort()
    .reduce((res: Record<string, any>, k: string) => ((res[k] = obj[k]), res), {});
}
