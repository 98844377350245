import { defineStore } from 'pinia';

interface IStateOverlay {
  displayer: { uuid: string; node: HTMLElement }[];
}

export const useOverlayStore = defineStore('overlay', {
  state: (): IStateOverlay => {
    return {
      displayer: [
        /* { uuid: uuid, node: nodeHtml } */
      ]
    };
  },
  actions: {
    addOverlay(caller: any) {
      this.displayer.push(caller);
    },
    removeOverlay(caller: any) {
      this.displayer = this.displayer.filter((d: any) => {
        return d.uuid !== caller.uuid;
      });
    },
    removeLastOverlay() {
      this.displayer.pop();
    }
  }
});
