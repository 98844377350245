import { defineStore } from 'pinia';

export interface IStatePopup {
  display: boolean;
  styleOverride: string;
  component: { loader: any } | null;
  props: any;
  persistent: boolean;
}

export interface IPopupOptions extends Omit<IStatePopup, 'component'> {
  component: any;
}

export const usePopupStore = defineStore('popup', {
  state: (): IStatePopup => {
    return {
      display: false,
      styleOverride: '',
      component: null,
      props: {},
      persistent: false
    };
  },
  actions: {
    set(popup: Partial<IPopupOptions>) {
      this.display = true;
      this.component = null;
      this.styleOverride = '';
      this.props = {};
      this.persistent = false;

      Object.keys(popup).forEach((key: string) => {
        this[key] = key === 'component' ? { loader: popup.component } : popup[key as keyof IStatePopup];
      });
    }
  }
});
