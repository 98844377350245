import { defineStore } from 'pinia';
import { Project } from '@/model/project';
import { referentials } from '@/const';
import { IReferential } from '@/types';
import { UnwrapRef } from 'vue';

export interface IState {
  project: Project | null;
  duplicate: boolean;
  referentials: { [key: string]: [IReferential] };
}

export const useStore = defineStore('main', {
  state: (): IState => {
    return {
      project: null,
      duplicate: false,
      referentials: {}
    };
  },
  getters: {
    getReferential: (state: UnwrapRef<IState>) => {
      return (key: string) => getFilteredReferential(state, key);
    }
  },
  actions: {
    setReferential(key: string, referential: IReferential[]) {
      this.referentials = {
        ...this.referentials,
        [key]: [...referential]
      };
    }
  }
});

function getFilteredReferential(state: UnwrapRef<IState>, key: string): IReferential[] {
  if (!state.referentials[referentials[key].storeKey]) return [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const dateIsValid = (item: IReferential) => {
    const dateFrom = new Date(item.dateDebut);
    dateFrom.setHours(0, 0, 0, 0);

    const dateTo = item.dateFin ? new Date(item.dateFin) : null;
    dateTo && dateTo.setHours(0, 0, 0, 0);

    return dateFrom <= today && (!dateTo || dateTo > today);
  };

  const groupBy = referentials[key].groupBy;
  return state.referentials[referentials[key].storeKey].filter((item) => {
    const parent = groupBy ? item[groupBy] : null;
    return dateIsValid(item) && (!parent || dateIsValid(parent));
  });
}
