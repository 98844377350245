/* c8 ignore start */
import { createI18n } from 'vue-i18n';
import fr from '@/locales/fr.json';

const options = {
  locale: 'fr',
  warnHtmlInMessage: 'off',
  silentTranslationWarn: true,
  messages: { fr }
};
const _i18n = createI18n(options);
export const i18n = _i18n.global;
export const i18nPlugin = _i18n;
/* c8 ignore stop */
