import { defineStore } from 'pinia';
import { omit, sort } from '@/utils/object';

export enum ProjectsFilter {
  Author = 'and[0][author]',
  Type = 'and[1][typeAide]',
  Theme = 'and[2][thematique]',
  Title = 'and[3][or][][titre]',
  FunctionalId = 'and[3][or][][idFonctionnel]',
  State = 'and[4][statut]',
  OpeningDate = 'and[5][dateFin][after]',
  ClosingDate = 'and[6][dateFin][strictly_before]',
  ClosingDate2 = 'and[7][exists][dateFin]',
  SupportedBy = 'and[8][prisEnChargePar]',
  TypeMultiPhase = 'and[9][typeMultiPhase]',
  PredepotAffecte = 'and[10][exists][appelAProjet.aapDepots]'
}

interface SearchPagination {
  page: number;
  itemsPerPage: number;
}

export interface IStateSearch {
  projects: SearchPagination & Record<ProjectsFilter | string, any>;
  states: object[];
}

const defautStateProject = {
  itemsPerPage: 10,
  page: 1,
  [ProjectsFilter.Author]: null,
  [ProjectsFilter.Type]: [],
  [ProjectsFilter.Theme]: [],
  [ProjectsFilter.Title]: null,
  [ProjectsFilter.FunctionalId]: null,
  [ProjectsFilter.State]: [],
  [ProjectsFilter.SupportedBy]: [],
  [ProjectsFilter.TypeMultiPhase]: [],
  [ProjectsFilter.PredepotAffecte]: []
};

export const useSearchStore = defineStore('search', {
  state: (): IStateSearch => {
    return {
      projects: { ...defautStateProject },
      states: []
    } as IStateSearch;
  },
  getters: {
    getProjectFiltersAsString: (state) => {
      return (filters?: Record<string, any>) => JSON.stringify(sort(omit(JSON.parse(JSON.stringify(filters ?? state.projects)), ['page'])));
    },
    isDefaultSearch: (state) => {
      return JSON.stringify(state.projects) === JSON.stringify(defautStateProject);
    }
  },
  actions: {
    resetProjectsSearch() {
      this.projects = { ...defautStateProject };
      this.states = [];
    }
  }
});
